import React from 'react';
import { BrowserRouter as Router, Route, BrowserRouter, Routes } from 'react-router-dom';
import ShareProfile from './ShareProfile';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:share_profile/:profile_id" Component={ShareProfile} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;