import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const ShareProfile = () => {
    let { profile_id, share_profile } = useParams();
    let navigate = useNavigate();

  useEffect(() => {
    const scheme = `${share_profile}://profile/${profile_id}`;
    const appStoreLink = process.env.REACT_APP_APPLE_LINK || "";
    const appLink = document.createElement('a');
    appLink.href = scheme;
    appLink.style.display = 'none';
    document.body.appendChild(appLink);

    let start = Date.now();
    let timeout:any;

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        clearTimeout(timeout);
        console.log("App opened successfully, clearing timeout.");
      }
    };

    const handlePageHide = () => {
      clearTimeout(timeout);
      console.log("Page is hidden, clearing timeout.");
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('pagehide', handlePageHide);

    appLink.click();

    timeout = setTimeout(() => {
      document.body.removeChild(appLink);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('pagehide', handlePageHide);

      // If the document is still visible and enough time has passed, redirect to the App Store
      if (document.visibilityState === 'visible') {
        console.log("App not installed, redirecting to the App Store.");
        window.location.href = appStoreLink;
      }
    }, 3000);  // Adjusted the timeout to 3000 ms

    console.log("Trying to open the app.");
  }, [navigate]);

  return (
    <div>
    </div>
  );
};

export default ShareProfile;
